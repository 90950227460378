#container {
    position: relative;
    z-index: 1000 !important;
}

#connecting-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1000 !important;
}

#columns {
    position: relative;
}

.box {
}

#first-column, #middle-column, #last-column {
}

/* Style the table */
table {
    background-color: white;
    border-collapse: collapse !important;
    width: 100%;
    font-size: 12px;
    color: #535b64;
    border-radius: 4px;
}

/* Style table header */
th {
    background-color: white;
    color: #535b64;
    font-weight: bold;
    padding: 2px;
    border-bottom: 1px solid #cfd4da;
    text-align: center;
}

/* Style table cells */
td {
    padding: 2px;
    border-bottom: 1px solid #cfd4da;
    text-align: center;
}


