body {
  margin: 0;
  min-height: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(1em * 0.625);
  display: "inline-block";
}

.selected {
  background-color: #e2e2eb;
}

.only-you-can-see-border {
  border: 1px solid blue;
}

.everyone-can-see-border {
  border: 1px solid #008A1F;
}