.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 20px;
    margin-bottom: 8px;
    border-radius: 4px;
}

.generative {
    white-space: pre-wrap !important;
    padding-top: 6px !important;
    color: #343A40 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    font-family: unset !important;
}